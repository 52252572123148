<template>
  <div class="beauty-ai-appointment-unit-analysis">
    <PageTitle title="AI 服務人員績效" hideBtn />
    <div class="flex items-start" style="gap: 6.5px; margin-bottom: 12px">
      <p class="relative text-gray-60" style="top: 5px">搜尋</p>
      <FiltersContainer>
        <SearchTextInput
          :value.sync="search.name"
          placeholder="輸入姓名"
          @refresh="refresh(true)"
        />
        <BaseSearch
          :value.sync="search.service"
          :api="apiFormatAdaptor(GetService)"
          placeholder="輸入服務項目"
          objKey="id"
          @clear="refresh(true)"
          @change="refresh(true)"
        />
      </FiltersContainer>
    </div>
    <DataTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import DataTable from './components/DataTable.vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import { GetService } from '@/api/service'
import { GetBeautyAIAptUnitAnalysis, GetBeautyAIAptUnitAnalysisCount } from '@/api/beautyAI'
import SearchTextInput from '@/components/Input/SearchTextInput.vue'
import BaseSearch from '@/components/Search/BaseSearch.vue'
import { apiFormatAdaptor } from '@/utils/api'

export default defineComponent({
  name: 'BeautyAIAppointmentUnitAnalysis',
  components: {
    PageTitle,
    DataTable,
    SearchTextInput,
    BaseSearch,
  },
  setup (props) {
    const router = useRouter()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    const search = reactive({
      name: null,
      service: null,
    })

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        appointmentServiceId: search.service || undefined,
        name: search.name || undefined,
      }
      await Promise.allSettled([
        fetchData(GetBeautyAIAptUnitAnalysis, payload),
        fetchDataCount(GetBeautyAIAptUnitAnalysisCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })

    return {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      refresh,
      search,
      GetService,
      apiFormatAdaptor,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
